<template>
  <b-row>
    <b-col>
      <chartjs-container :title="chartTitle">
        <chartjs-component-bar-chart v-if="hasMeaningfulData" :height="chartHeight" :chart-data="chartData" />
        <p v-else>Aucune information disponible</p>
      </chartjs-container>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue'
import ChartjsContainer from '../ChartjsContainer.vue'
import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
import useStatisticsRouteFetch from '../useStatisticsRouteFetch'
import {
  computedConfiguration, watchConfiguration, BASE_CHART_HEIGHT, chartHasMeaningfulData, TABS,
} from './baseConfiguration'

export default {
  components: {
    BCol,
    BRow,
    ChartjsContainer,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      tabname: TABS.ACCOMMODATION,
      chartTitle: "Situation d'hébergement au moment de l'appel pour les demandes LGBTQIA+",
      chartData: {},
      chartHeight: BASE_CHART_HEIGHT,
    }
  },
  computed: {
    ...computedConfiguration,
    hasMeaningfulData() {
      return chartHasMeaningfulData(this.chartData)
    },
  },
  watch: watchConfiguration,
  methods: {
    fetchRouteOnActive() {
      if (this.active) {
        this.fetchRoute()
      }
    },
    async fetchRoute() {
      const response = await useStatisticsRouteFetch()
      this.buildChart(response)
    },
    buildChart(data) {
      const labels = this.formatLabels(data)
      const datas = this.getData(data)
      this.chartData = {
        labels,
        datasets: [
          {
            label: 'Nombre de demandes',
            data: datas,
            backgroundColor: '#16B5EA',
          },
        ],
      }
    },
    formatLabels(data) {
      return Object.keys(data)?.map(key => data[key].label)
    },
    getData(data) {
      return Object.keys(data)?.map(key => data[key].value)
    },
  },
}

</script>
