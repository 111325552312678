<template>
  <b-col :sm="12" class="my-2">
    <b-button v-for="button in buttons" :key="button.value" variant="outline-primary" class="mr-1"
      :class="{ current: button.value === demandType }" @click="setDemandType(button.value)">
      {{ button.label }}
    </b-button>
  </b-col>
</template>

<script>
import {
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BButton,
  },
  props: {
    demandType: Number,
  },
  data() {
    return {
      buttons: [
        {
          label: 'Tous les appels',
          value: 0,
        },
        {
          label: 'Demande LGBTQIA+',
          value: 1,
        },
        {
          label: 'Demande Autre',
          value: 2,
        },
        {
          label: 'Canular',
          value: 3,
        },
      ],
    }
  },
  methods: {
    setDemandType(value) {
      this.$emit('set-demand-type', value)
    },
  },
}

</script>

<style>
.current {
  background-color: rgba(22, 181, 234, 0.2);
  color: #16B5EA;
}
</style>
