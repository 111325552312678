<template>
  <b-row>
    <b-col>
      <div>
        <b-button class="stas-prev-button stats-button stats-button-primary mr-1" type="button" aria-label="prev"
          @click="changeDatePeriod('prev')">
          Précédent
        </b-button>
        <b-button class="stats-next-button stats-button stats-button-primary" type="button" aria-label="next"
          @click="changeDatePeriod('next')">
          Suivant
        </b-button>
        <h2 class="statistics__toolbar-title">
          <span v-if="mode === 'd'">{{ startDate }}</span>
          <span v-else>{{ startDate }} - {{ endDate }}</span>
        </h2>
      </div>
    </b-col>
    <b-col class="d-flex justify-content-end">
      <div v-for="(view, index) in views" :key="index">
        <b-button :class="{ dateView: view.value === mode }" class="mr-1" value="view.value" variant="outline-primary"
          @click="selectView(view.mode, view.value)">
          {{ view.mode }}
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import * as dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import * as Locale from 'dayjs/locale/fr'

dayjs.extend(LocalizedFormat)
dayjs.locale(Locale)

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      views: [
        { mode: 'Jour', value: 'd' },
        { mode: 'Semaine', value: 'w' },
        { mode: 'Mois', value: 'M' },
        { mode: 'Année', value: 'y' },
      ],
      correspondance: {
        Jour: 'day',
        Semaine: 'week',
        Mois: 'month',
        Année: 'year',
      },
      startDate: '',
      endDate: '',
      currentDate: dayjs(),
      currentView: 'day',
      mode: 'd',
    }
  },
  mounted() {
    this.triggerUpdate()
  },
  methods: {
    triggerUpdate() {
      this.commitValues()
      this.formatViewDates()
    },
    commitValues() {
      this.$store.dispatch('chart/setStartDate', this.currentDate.startOf(this.currentView))
      this.$store.dispatch('chart/setEndDate', this.currentDate.endOf(this.currentView))
    },
    selectView(view, mode) {
      this.currentDate = dayjs()
      this.currentView = this.correspondance[view]
      this.mode = mode
      this.triggerUpdate()
    },
    changeDatePeriod(view) {
      const addedValue = view === 'prev' ? -1 : 1
      this.currentDate = this.currentDate.add(addedValue, this.mode)
      this.triggerUpdate()
    },
    formatViewDates() {
      const start = this.currentDate.startOf(this.currentView)
      const end = this.currentDate.endOf(this.currentView)
      this.startDate = start.format('dddd ll')
      this.endDate = end.format('dddd ll')
    },
  },
}

</script>
