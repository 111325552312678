const computedConfiguration = {
  start() {
    return this.$store.getters['chart/getPeriod'].start
  },
  end() {
    return this.$store.getters['chart/getPeriod'].end
  },
  active() {
    return this.$store.getters['chart/getTab'] === this.tabname
  },
}

const watchConfiguration = {
  end: {
    handler() {
      this.fetchRouteOnActive()
    },
  },
  active: {
    handler() {
      this.fetchRouteOnActive()
    },
  },
}

const watchConfigurationWithDemandType = {
  ...watchConfiguration,
  demandType: {
    handler() {
      this.fetchRoute()
    },
  },
}

const chartHasMeaningfulData = data => data?.datasets?.at(0)?.data?.some(value => value > 0)

const BASE_CHART_HEIGHT = 400
const AUGMENTED_CHART_HEIGHT = 2000

const TABS = {
  CALLS: 'Calls',
  LOCATION: 'Location',
  GENDERAGE: 'GenderAge',
  DEMANDREASON: 'DemandReason',
  NOTORIETY: 'Notoriety',
  ACCOMMODATION: 'Accommodation',
}

export {
  computedConfiguration, watchConfiguration, watchConfigurationWithDemandType, BASE_CHART_HEIGHT, AUGMENTED_CHART_HEIGHT, chartHasMeaningfulData, TABS,
}
