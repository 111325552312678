<template>
  <div class="d-flex justify-content-between stats_details">
    <div class="d-flex align-items-center">
      <feather-icon :icon="icon"
                    size="36" />
      <p class="statistics__card-text">
        {{ text }}
      </p>
    </div>
    <div class="d-flex align-items-center">
      <p v-if="text === 'Moyenne des appels'" class="statistics__card-number">
        {{ convertHMS(data) }}
      </p>
      <p v-else class="statistics__card-number">
        {{ data }}
      </p>
    </div>
  </div>
</template>

<script>
  import { convertHMS } from '@core/utils/filter'

  export default {
    props: {
      data: {
        type: Number,
        default: 0,
      },
      text: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
    },
    setup() {
      return {
        convertHMS,
      }
    },
  }
</script>

<style lang="scss" scoped>
.stats_details {
  margin: 1rem;
}
</style>
