<template>
  <b-row>
    <b-col :md="6">
      <chartjs-container :title="lgbtChartTitle">
        <chartjs-component-horizontal-bar-chart v-if="lgbtChartHasMeaningfulData" :height="chartHeight"
          :chart-data="lgbtChartData" />
        <p v-else>Aucune information disponible</p>
      </chartjs-container>
    </b-col>
    <b-col :md="6">
      <chartjs-container :title="otherChartTitle">
        <chartjs-component-horizontal-bar-chart v-if="othersChartHasMeaningfulData" :height="chartHeight"
          :chart-data="otherChartData" />
        <p v-else>Aucune information disponible</p>
      </chartjs-container>
    </b-col>
  </b-row>
</template>

<script>

import {
  BCol,
  BRow,
} from 'bootstrap-vue'
import ChartjsContainer from '../ChartjsContainer.vue'
import ChartjsComponentHorizontalBarChart from '../charts-components/ChartjsComponentHorizontalBarChart.vue'
import useStatisticsRouteFetch from '../useStatisticsRouteFetch'
import {
  computedConfiguration, watchConfiguration, BASE_CHART_HEIGHT, chartHasMeaningfulData, TABS,
} from './baseConfiguration'

export default {
  components: {
    BCol,
    BRow,
    ChartjsContainer,
    ChartjsComponentHorizontalBarChart,
  },
  data() {
    return {
      tabname: TABS.DEMANDREASON,
      lgbtChartTitle: 'Demandes LGBTQIA+',
      otherChartTitle: 'Autres demandes',
      lgbtChartData: {},
      otherChartData: {},
      chartHeight: BASE_CHART_HEIGHT,
    }
  },
  computed: {
    lgbtChartHasMeaningfulData() {
      return chartHasMeaningfulData(this.lgbtChartData)
    },
    othersChartHasMeaningfulData() {
      return chartHasMeaningfulData(this.otherChartData)
    },
    ...computedConfiguration,
  },
  watch: watchConfiguration,
  methods: {
    fetchRouteOnActive() {
      if (this.active) {
        this.fetchRoute()
      }
    },
    async fetchRoute() {
      const response = await useStatisticsRouteFetch()
      this.lgbtChartData = this.buildChartData(response.lgbtqia_Plus)
      this.otherChartData = this.buildChartData(response.others)
    },
    buildChartData(data) {
      const formattedLabels = this.formatLabels(data)
      const formattedData = this.formatValues(data)
      return {
        labels: formattedLabels,
        datasets: [{
          label: 'Nombre de demandes',
          data: formattedData,
          backgroundColor: '#16b5ea70', // A noter, il faut définir une couleur (ou une palette) pour les graphs verticaux
        }],
      }
    },
    formatLabels(data) {
      return Object.keys(data).map((key => data[key].label))
    },
    formatValues(data) {
      return Object.keys(data).map((key => data[key].value))
    },
  },
}

</script>
