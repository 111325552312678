<template>
  <b-row>
    <b-col>
      <chartjs-container :title="chartTitle">
        <chartjs-component-stacked-horizontal-bar-chart v-if="hasMeaningfulData" :height="chartHeight"
          :chart-data="chartData" />
        <p v-else>Aucune information disponible</p>
      </chartjs-container>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue'
import ChartjsContainer from '../ChartjsContainer.vue'
import ChartjsComponentStackedHorizontalBarChart from '../charts-components/ChartjsComponentStackedHorizontalBarChart.vue'
import {
  BASE_CHART_HEIGHT, watchConfiguration, computedConfiguration, TABS,
} from './baseConfiguration'
import useStatisticsRouteFetch from '../useStatisticsRouteFetch'

export default {
  components: {
    BCol,
    BRow,
    ChartjsContainer,
    ChartjsComponentStackedHorizontalBarChart,
  },
  data() {
    return {
      tabname: TABS.NOTORIETY,
      chartTitle: 'Comment nous avez-vous connu ?',
      chartHeight: BASE_CHART_HEIGHT,
      chartData: {},
    }
  },
  computed: {
    // this one is a bit different from the other tabs because we work with two datasets
    hasMeaningfulData() {
      return this.chartData.datasets?.at(0)?.data?.some(value => value > 0)
        || this.chartData.datasets?.at(1)?.data?.some(value => value > 0)
    },
    ...computedConfiguration,
  },
  watch: watchConfiguration,
  methods: {
    fetchRouteOnActive() {
      if (this.active) {
        this.fetchRoute()
      }
    },
    async fetchRoute() {
      const response = await useStatisticsRouteFetch()
      this.buildChartData(response)
    },
    buildChartData(response) {
      const labels = this.formatLabels(response)
      const lgbtData = this.getData(response, 'lgbtqia')
      const othersData = this.getData(response, 'others')
      this.chartData = {
        labels,
        datasets: [
          {
            label: 'LGBTQIA+',
            backgroundColor: '#f87979',
            data: lgbtData,
          },
          {
            label: 'Autres demandes',
            backgroundColor: '#16B5EA',
            data: othersData,
          },
        ],
      }
    },
    formatLabels(response) {
      return Object.keys(response).map(key => response[key].label)
    },
    getData(response, type) {
      return Object.keys(response).map(key => response[key][type])
    },
  },
}
</script>
