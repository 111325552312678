<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-button v-if="extendedViewButton" variant="link" class="float-right py-0" @click="setExtendedView"> {{ btnText
      }}</b-button>
    </b-card-header>
    <b-card-body>
      <slot />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    extendedViewButton: {
      type: Boolean,
      default: false,
    },
    extendedState: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnText() {
      return this.extendedState ? 'Vue Réduite' : 'Vue Etendue'
    },
  },
  methods: {
    setExtendedView() {
      this.$emit('set-extended-view')
    },
  },
}
</script>
