<template>
  <b-row>
    <demand-filters :demand-type="demandType" @set-demand-type="setDemandType" />
    <b-col :md="6">
      <chartjs-container :title="pieTitle">
        <chartjs-component-pie-chart v-if="pieHasMeaningfulData" :height="chartHeight" :chart-data="pieData"
          :chartTooltips="tooltips" />
        <p v-else>Aucune information disponible</p>
      </chartjs-container>
    </b-col>
    <b-col :md="6">
      <chartjs-container :title="barTitle">
        <chartjs-component-bar-chart v-if="barChartHasMeaningfulData" :height="chartHeight" :chart-data="barData" />
        <p v-else>Aucune information disponible</p>
      </chartjs-container>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue'
import DemandFilters from '../DemandFilters.vue'
import ChartjsComponentPieChart from '../charts-components/ChartjsComponentPieChart.vue'
import ChartjsContainer from '../ChartjsContainer.vue'
import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
import useStatisticsRouteFetch from '../useStatisticsRouteFetch'
import {
  computedConfiguration, watchConfigurationWithDemandType, BASE_CHART_HEIGHT, chartHasMeaningfulData, TABS,
} from './baseConfiguration'

export default {
  components: {
    BCol,
    BRow,
    ChartjsContainer,
    ChartjsComponentBarChart,
    ChartjsComponentPieChart,
    DemandFilters,
  },
  data() {
    return {
      tooltips: {
        enabled: true,
        callbacks: {
          label: (tooltipValue, data) => {
            const numericValue = data.datasets[0].data[tooltipValue.index]
            const total = data.datasets[0].data.reduce((acc, curr) => acc + curr, 0)
            const percentage = Math.round((numericValue / total) * 100)
            return `${data.labels[tooltipValue.index]} - Demandes: ${numericValue} - Pourcentage: ${percentage} %`
          },
        },
      },
      tabname: TABS.GENDERAGE,
      demandType: 0,
      pieTitle: 'Répartition par genre',
      barTitle: 'Répartition par tranche d\'âge',
      pieData: {},
      barData: {},
      colors: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
      ],
      pieLabels: [
        'blue',
        'green',
        'red',
        'yellow',
        'purple',
        'grey',
      ],
      chartHeight: BASE_CHART_HEIGHT,
    }
  },
  computed: {
    pieHasMeaningfulData() {
      return chartHasMeaningfulData(this.pieData)
    },
    barChartHasMeaningfulData() {
      return chartHasMeaningfulData(this.barData)
    },
    ...computedConfiguration,
  },
  watch: watchConfigurationWithDemandType,
  methods: {
    fetchRouteOnActive() {
      if (this.active) {
        this.fetchRoute()
      }
    },
    async fetchRoute() {
      const response = await useStatisticsRouteFetch(this.demandType)
      this.constructPieChart(response.genderPie)
      this.constructBarChart(response.ageBar)
    },
    setDemandType(type) {
      this.demandType = type
    },
    constructBarChart(data) {
      const labels = this.formatBarLabels(data)
      const datasets = this.formatBarData(data)
      this.barData = { labels, datasets }
    },
    formatBarLabels(data) {
      return data.map(entry => entry.label)
    },
    formatBarData(data) {
      return [
        {
          label: 'Tranche d\'âge',
          data: data.map(entry => entry.value),
          backgroundColor: this.colors[0],
        },
      ]
    },
    constructPieChart(data) {
      const labels = data.map(entry => entry.label)
      const values = data.map(entry => entry.value)

      this.pieData = {
        labels,
        datasets: [
          {
            labels: 'Répartition par genre',
            backgroundColor: this.pieLabels,
            data: values,
          },
        ],
      }
    },
  },
}

</script>
