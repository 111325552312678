<template>
  <b-row>
    <b-col :md="4">
      <b-card class="statistics__card">
        <div>
          <DisplayCategoryMessage :data="totalInfo.totalYoungs" text="Nombre de personnes" icon="UserIcon" />
          <DisplayCategoryMessage :data="callDurantionAvarage" text="Moyenne des appels" icon="SmartphoneIcon" />
          <DisplayCategoryMessage :data="totalInfo.callReceived" text="Appels reçus" icon="SmartphoneIcon" />
          <DisplayCategoryMessage :data="totalInfo.callEmitted" text="Appels émis" icon="SmartphoneIcon" />
          <DisplayCategoryMessage :data="totalInfo.smsReceived" text="Sms reçus" icon="MessageSquareIcon" />
          <DisplayCategoryMessage :data="totalInfo.smsEmitted" text="Sms émis (hors Whatsapp)" icon="MessageSquareIcon" />
          <DisplayCategoryMessage :data="totalInfo.whatsAppReceived" text="Whatsapp reçus" icon="MessageCircleIcon" />
          <DisplayCategoryMessage :data="totalInfo.whatsAppEmitted" text="Whatsapp émis" icon="MessageCircleIcon" />

        </div>
      </b-card>
    </b-col>
    <b-col :md="4">
      <chartjs-container :title="barChartName">
        <chartjs-component-bar-chart :height="chartHeight" :chart-data="chartData" />
      </chartjs-container>
    </b-col>
    <b-col :md="4">
      <chartjs-container :title="pieChartName">
        <chartjs-component-pie-chart v-if="pieHasMeaningfulData" :height="chartHeight" :chart-data="pieData"
                                     :chart-tooltips="tooltips" />
        <p v-else>
          Aucune information disponible
        </p>
      </chartjs-container>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'
import ChartjsComponentPieChart from '../charts-components/ChartjsComponentPieChart.vue'
import ChartjsContainer from '../ChartjsContainer.vue'
import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
import DisplayCategoryMessage from '../displayCategoryMessage.vue'
import {
  computedConfiguration, watchConfiguration, BASE_CHART_HEIGHT, chartHasMeaningfulData, TABS,
} from './baseConfiguration'
import useStatisticsRouteFetch from '../useStatisticsRouteFetch'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    ChartjsContainer,
    DisplayCategoryMessage,
    ChartjsComponentPieChart,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      tooltips: {
        enabled: true,
        callbacks: {
          label: (tooltipValue, data) => {
            const numericValue = data.datasets[0].data[tooltipValue.index]
            const total = data.datasets[0].data.reduce((acc, curr) => acc + curr, 0)
            const percentage = Math.round((numericValue / total) * 100)
            return `Demandes: ${numericValue} - Pourcentage: ${percentage} %`
          },
        },
      },
      tabname: TABS.CALLS,
      totalInfo: {},
      statsInfo: [],
      chartLabel: [],
      colors: {
        'Appels émis': 'red',
        'Appels Reçus': 'blue',
        'SMS reçu': 'orange',
        'Sms émis': 'purple',
        'WhatsApp reçus': 'green',
        'WhatsApp émis': 'yellow',
      },
      demandTypeColors: {
        Autres: '#16B5EA',
        'Lgbtqia+': '#EAC4D5',
        'Menaces/Canulars/Insultes': '#D84727',
      },
      barChartName: 'Statistiques des appels',
      pieChartName: 'Type de demande',
      pieData: {},
      chartHeight: BASE_CHART_HEIGHT,
    }
  },
  computed: {
    callDurantionAvarage() {
      return Math.round(this.totalInfo.callDurantionAvarage * 100) / 100
    },
    chartData() {
      return {
        labels: this.chartLabel,
        datasets: this.statsInfo,
      }
    },
    pieHasMeaningfulData() {
      return chartHasMeaningfulData(this.pieData)
    },
    ...computedConfiguration,
  },
  watch: watchConfiguration,
  methods: {
    async fetchRouteOnActive() {
      if (this.active) {
        const response = await useStatisticsRouteFetch()
        this.totalInfo = response
        this.constructBarChartObj(response)
        this.formatPieData(response.demandType)
      }
    },
    constructBarChartObj(response) {
      const labels = {
        'Appels Reçus': response.callReceived,
        'Appels émis': response.callEmitted,
        'SMS reçu': response.smsReceived,
        'Sms émis': response.smsEmitted,
        'WhatsApp reçus': response.whatsAppReceived,
        'WhatsApp émis': response.whatsAppEmitted,
      }
      this.chartLabel = ['Type de communication']
      this.statsInfo = this.formatData(labels)
    },
    formatData(labels) {
      return Object.keys(labels).map(label => ({
        label,
        data: [labels[label]],
        backgroundColor: this.colors[label],
      }))
    },
    formatPieData(data) {
      this.pieData = {
        labels: data.map(demand => demand.label),
        datasets: [
          {
            backgroundColor: data.map(demand => this.demandTypeColors[demand.label] || '#C5DAC1'),
            data: data.map(demand => demand.value),
          },
        ],
      }
    },
  },
}
</script>
