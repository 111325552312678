<template>
  <div class="statistics">
    <period-selector />
    <b-tabs>
      <b-tab title="Appels" active @click="setTab('CALLS')">
        <global-display />
      </b-tab>
      <b-tab title="Localisation" @click="setTab('LOCATION')">
        <location-display />
      </b-tab>
      <b-tab title="Genre/Age" @click="setTab('GENDERAGE')">
        <gender-age-display />
      </b-tab>
      <b-tab title="Motif demande" @click="setTab('DEMANDREASON')">
        <demand-reason-display />
      </b-tab>
      <b-tab title="Notoriété" @click="setTab('NOTORIETY')">
        <notoriety-display />
      </b-tab>
      <b-tab title="Hébergement" @click="setTab('ACCOMMODATION')">
        <accomodation-display />
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue'
import GlobalDisplay from './tabs/GlobalDisplay.vue'
import AccomodationDisplay from './tabs/AccomodationDisplay.vue'
import LocationDisplay from './tabs/LocationDisplay.vue'
import GenderAgeDisplay from './tabs/GenderAgeDisplay.vue'
import DemandReasonDisplay from './tabs/DemandReasonDisplay.vue'
import PeriodSelector from './PeriodSelector.vue'
import NotorietyDisplay from './tabs/NotorietyDisplay.vue'
import { TABS } from './tabs/baseConfiguration'

export default {
  components: {
    BTabs,
    BTab,
    GlobalDisplay,
    AccomodationDisplay,
    LocationDisplay,
    GenderAgeDisplay,
    DemandReasonDisplay,
    PeriodSelector,
    NotorietyDisplay,
  },
  methods: {
    setTab(name) {
      this.$store.dispatch('chart/setTab', TABS[name])
    },
  },
}
</script>

<style lang="scss">
.statistics {
  &__card-text {
    font-size: 16px;
    align-items: center;
    display: flex;
    margin: 0 0 0 0.5rem;

    p {
      margin: 0;
    }
  }

  &__toolbar-title {
    margin: 0.5rem 0
  }

  &__card-number {
    margin: 0;
  }

  .dateView {
    background-color: rgba(22, 181, 234, 0.2)
  }
}
</style>
